import React from 'react';

const KontaktPage = ({ data, location }) => {
  return (
    <div>
      <h1>Kontakt</h1>
      <p>Waiting to be filled with content</p>
    </div>
  );
};

export default KontaktPage;
